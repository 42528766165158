<div class="contact-us">
  <div class="headline-medium contact-us__title">
    Contact us
  </div>

  <form
    #ngForm="ngForm"
    [formGroup]="form"
    class="contact-us__form"
    (ngSubmit)="onSubmit()">
    <div class="contact-us__form-row contact-us__form-row--50p">
      <mat-form-field appearance="fill">
        <mat-label>
          Name
        </mat-label>

        <input
          matInput
          autocomplete="off"
          [errorStateMatcher]="matcher"
          formControlName="name"/>

        <div
          class="icon-clean"
          *ngIf="(form.get('name').value !== null) && (form.get('name').value.length > 0)"
          (click)="clearField('name')"
          matSuffix></div>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>
          Email*
        </mat-label>

        <input
          matInput
          autocomplete="off"
          [errorStateMatcher]="matcher"
          formControlName="email"/>

        <div
          class="icon-clean"
          *ngIf="(form.get('email').value !== null) && (form.get('email').value.length > 0) && (!ngForm.submitted || !form.get('email').errors)"
          (click)="clearField('email')"
          matSuffix></div>

        <div
          class="icon-error"
          *ngIf="ngForm.submitted && (form.get('email').errors?.required || form.get('email').errors?.email)"
          [matTooltip]="form.get('email').errors?.email ? 'Enter valid email' : 'This field is required'"
          matTooltipPosition="right"
          matSuffix></div>
      </mat-form-field>
    </div>

    <div class="contact-us__form-row">
      <mat-form-field appearance="fill">
        <mat-label>
          Message*
        </mat-label>

        <textarea
          matInput
          rows="1"
          autocomplete="off"
          [errorStateMatcher]="matcher"
          formControlName="message"></textarea>

        <div
          class="icon-clean"
          *ngIf="(form.get('message').value !== null) && (form.get('message').value.length > 0) && (!ngForm.submitted || !form.get('message').errors)"
          (click)="clearField('message')"
          matSuffix></div>

        <div
          class="icon-error"
          *ngIf="ngForm.submitted && form.get('message').errors?.required"
          matTooltip="This field is required"
          matTooltipPosition="right"
          matSuffix></div>
      </mat-form-field>
    </div>

    <div class="contact-us__form-row" *ngIf="serverError">
      <div class="error-text">
        <span class="icon-error"></span>
        <span class="body-large">
          I couldn’t send the message. Contact us at enquires@admonitor.tech
        </span>
      </div>
    </div>

    <div class="contact-us__form-row" *ngIf="serverSuccess">
      <div class="success-text">
        <span class="icon-success"></span>
        <span class="body-large">
          Letter sent!
        </span>
      </div>
    </div>

    <div class="contact-us__form-row contact-us__form-row--start">
      <button
        [disabled]="sending"
        class="button"
        type="submit">
        <mat-spinner *ngIf="sending" [diameter]="24"></mat-spinner>
        <div *ngIf="!sending">
          Send
        </div>
      </button>
    </div>
  </form>

  <div class="body-small contact-us__agree">
    By clicking on the button, you agree to our Policy regarding the processing of personal data of users
  </div>
</div>
