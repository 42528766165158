<div class="solution">
  <div class="headline-large solution__title">
    Solution
  </div>

  <ul class="solution__list">
    <li class="solution__item" *ngFor="let item of data">
      <div class="solution__item-picture">
        <img src="/assets/images/pictures/{{item.picture}}" [alt]="item.name + ' picture'">
      </div>

      <div class="title-large">
        {{item.name}}
      </div>

      <div class="body-medium">
        {{item.text}}
      </div>
    </li>
  </ul>
</div>
