<div class="technologies">
  <div class="headline-large technologies__title">
    Technologies
  </div>

  <ul class="technologies__list">
    <li class="technologies__item" *ngFor="let item of data">
      <div class="technologies__item-wrapper">
        <div class="title-large" [innerText]="item.name"></div>
        <div class="body-medium" [innerText]="item.text"></div>
        <div class="technologies__item-code" *ngIf="item.code">
          <p>JSON response:</p>
          <pre><code>{{item.code}}</code></pre>
        </div>
      </div>

      <div class="surface">
        <div class="surface__pictures">
          <img [src]="item.block.picture" [alt]="item.name + ' picture'">
        </div>

        <div
          class="surface__text"
          *ngIf="item?.block?.text || item?.block?.textIcon">
          <img *ngIf="item.block.textIcon" [src]="item.block.textIcon" alt="icon">
          <div class="body-large"
               *ngIf="item?.block?.text"
               [innerHtml]="item.block.text"></div>
        </div>
      </div>
    </li>
  </ul>
</div>
