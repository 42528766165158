<header class="header">
    <div
      class="header__logo"
      (click)="scrollTo('scroll-to-top')">
      <img src="/assets/images/logo.svg" alt="logo"/>
    </div>

    <ul class="header__scroll-nav" #headerNav>
      <li
        #topTechnologies
        class="body-medium header__scroll-item"
        (click)="scrollTo('scroll-to-technologies')">
        Technologies
      </li>
      <li
        #topUseCases
        class="body-medium header__scroll-item"
        (click)="scrollTo('scroll-to-use-cases')">
        Use cases
      </li>
      <li
        #topSolution
        class="body-medium header__scroll-item"
        (click)="scrollTo('scroll-to-solution')">
        Solution
      </li>
    </ul>

  <div class="header__wrapper">
    <button
      #topContactUs
      class="button"
      (click)="scrollTo('scroll-to-contact-us')">
      Contact us
    </button>

    <div
      class="mobile-button"
      (click)="scrollTo('scroll-to-contact-us')">
      <div class="circular">
        <div class="inner"></div>
        <div class="circle" *ngIf="false">
          <div class="bar left">
            <div class="progress"></div>
          </div>
          <div class="bar right">
            <div class="progress"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
