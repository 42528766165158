import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface ContactForm {
  name: string;
  email: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(
    protected http: HttpClient,
  ) {
  }

  postMessage(contactForm: ContactForm) {
    const url = '/mail.php';

    return this.http.post<{ data: object }>(url, {
      'name': contactForm.name,
      'email': contactForm.email,
      'message': contactForm.message
    });
  }
}
