import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {
  data = [{
    name: 'Gender and Age',
    text: 'Best in-class technology to estimate gender and age by face on the photo of one or multiple peoples',
    code: '{\n\t"age":29.010433,\n\t"gender":0.997958\n}',
    block: {
      picture: '/assets/images/pictures/img_1.png',
      text: '👨&ensp;Male (probability 99.8%)\nage: 29'
    }
  }, {
    name: 'Geo-location',
    text: 'Estimate audience geo-location with 97% accuracy based on the open information in the communit',
    code: '{\n\t"prediction":"FR",\n\t"probability":0.9299901973\n}',
    block: {
      picture: '/assets/images/pictures/img_2.png',
      text: '🇫🇷&ensp;France\n(probability 93%)'
    }
  }, {
    name: 'Brands',
    text: 'Detect brand mentioning in the text and picture.\nActionable database of the 10217 brands',
    code: '{\n\t“brands”: [\n\t\t{ “id”: 113, “name”: “Nike”}\n\t]\n}',
    block: {
      picture: '/assets/images/pictures/img_3.png',
      text: 'Nike',
      textIcon: '/assets/images/icons/nike.svg'
    }
  }, {
    name: 'Topic and interests',
    text: 'Classify topics of the discussions in the community and interests of the members',
    code: '',
    block: {
      picture: '/assets/images/pictures/img_4.png'
    }
  }];

  constructor() {
  }

  ngOnInit(): void {
  }

}
