import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from '../../../../matcher/custom-error-state.matcher';
import { ContactFormService } from "../../services/contact-form.service";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {
  private readonly subscriptions$ = new Subscription();
  matcher = new CustomErrorStateMatcher();

  form = this.fb.group({
    name: [null, []],
    email: [null, [Validators.required, Validators.email]],
    message: [null, [Validators.required]],
  });

  sending = false;
  serverError = false;
  serverSuccess = false;

  constructor(
    private fb: FormBuilder,
    private contactForm: ContactFormService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  onSubmit() {
    if (this.form.invalid || this.sending) {
      return false;
    }

    this.serverError = false;
    this.serverSuccess = false;
    this.sending = true;

    this.subscriptions$.add(
      this.contactForm
        .postMessage(this.form.getRawValue())
        .subscribe((response: any) => {
          this.sending = false;
          if (response['success']) {
            this.serverSuccess = true;
            this.serverError = false;
          } else {
            this.serverSuccess = false;
            this.serverError = true;
          }

        }, () => {
          this.sending = false;
          this.serverSuccess = false;
          this.serverError = true;
        })
    );
  }

  clearField(control: string) {
    this.form.get(control).setValue('');
  }
}
