<div class="footer">
  <p class="body-medium">
    AdMonitor Technologies Inc
  </p>
  <p class="body-medium">
    Reg number: ICC20220287
  </p>
  <p class="body-medium">
    Address: Office 706, Jumeirah Bay X2 Tower, Cluster X, Jumeriah Lakes Towers, P.O. Box 337188, Dubai, United Arab
    Emirates
  </p>
</div>
