<div class="use-cases">
  <div class="headline-large use-cases__title">
    Use cases
  </div>

  <ul class="use-cases__list">
    <li class="use-cases__item" *ngFor="let item of data">
      <div class="body-large use-cases__item-number">
        {{item.number}}
      </div>
      <div class="title-large">
        {{item.name}}
      </div>
      <div class="body-medium">
        {{item.text}}
      </div>
    </li>
  </ul>
</div>
