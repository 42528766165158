<div class="landing">
  <app-header></app-header>

  <div class="display-large landing__title">
    Technology to analyse the full picture of your audience on social media or online community
  </div>

  <app-technologies id="scroll-to-technologies"></app-technologies>
  <app-use-cases id="scroll-to-use-cases"></app-use-cases>
  <app-solution id="scroll-to-solution"></app-solution>
  <app-contact-us id="scroll-to-contact-us"></app-contact-us>
  <app-footer></app-footer>
</div>
