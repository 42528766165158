import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss']
})
export class SolutionComponent implements OnInit {
  data = [{
    picture: 'img_api.svg',
    name: 'On-Demand',
    text: 'You may integrate our well-documented API and use it in your workflow'
  }, {
    picture: 'img_box.svg',
    name: 'On Premise',
    text: 'Or we can install everyone into your infrastructure to conform your internal standards'
  }];

  constructor() {
  }

  ngOnInit(): void {
  }

}
