import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('headerNav', {static: true}) headerNav: ElementRef;

  @ViewChild('topTechnologies', {static: true}) topTechnologies: ElementRef;
  @ViewChild('topUseCases', {static: true}) topUseCases: ElementRef;
  @ViewChild('topSolution', {static: true}) topSolution: ElementRef;
  @ViewChild('topContactUs', {static: true}) topContactUs: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
    let currentScrollActivate = () => {
      const topTechnologiesNav = this.topTechnologies.nativeElement;
      const topUseCasesNav = this.topUseCases.nativeElement;
      const topSolutionNav = this.topSolution.nativeElement;

      const topPosition = document.documentElement.scrollTop;
      const technologiesElement = document.getElementById('scroll-to-technologies');
      const useCasesElement = document.getElementById('scroll-to-use-cases');
      const solutionElement = document.getElementById('scroll-to-solution');
      const contactUsElement = document.getElementById('scroll-to-contact-us');
      const scrollIndent = 100;

      let technologiesPosition = technologiesElement.offsetTop;
      let useCasesPosition = useCasesElement.offsetTop;
      let solutionPosition = solutionElement.offsetTop;
      let contactUsPosition = contactUsElement.offsetTop;

      if (((topPosition + scrollIndent) < useCasesPosition) && ((topPosition + scrollIndent) >= technologiesPosition)) {
        topTechnologiesNav.classList.add('header__scroll-item--current');
        topUseCasesNav.classList.remove('header__scroll-item--current');
        topSolutionNav.classList.remove('header__scroll-item--current');
      } else if (((topPosition + scrollIndent) < solutionPosition) && ((topPosition + scrollIndent) >= useCasesPosition)) {
        topTechnologiesNav.classList.remove('header__scroll-item--current');
        topUseCasesNav.classList.add('header__scroll-item--current');
        topSolutionNav.classList.remove('header__scroll-item--current');
      } else if (((topPosition + scrollIndent) < contactUsPosition) && ((topPosition + scrollIndent) >= solutionPosition)) {
        topTechnologiesNav.classList.remove('header__scroll-item--current');
        topUseCasesNav.classList.remove('header__scroll-item--current');
        topSolutionNav.classList.add('header__scroll-item--current');
      } else {
        topTechnologiesNav.classList.remove('header__scroll-item--current');
        topUseCasesNav.classList.remove('header__scroll-item--current');
        topSolutionNav.classList.remove('header__scroll-item--current');
      }
    };

    window.onscroll = function () {
      currentScrollActivate();
    };

    window.onresize = function () {
      currentScrollActivate();
    };
  }

  scrollTo(hash: string) {
    const topPosition = document.documentElement.scrollTop;
    const scrollTopIndent = -100;
    let scrollPosition = 0;

    switch (hash) {
      case 'scroll-to-top': {
        scrollPosition = 0;

        break;
      }
      case 'scroll-to-technologies': {
        const technologiesElement = document.getElementById('scroll-to-technologies');
        scrollPosition = technologiesElement.offsetTop + scrollTopIndent;

        break;
      }
      case 'scroll-to-use-cases': {
        const useCasesElement = document.getElementById('scroll-to-use-cases');
        scrollPosition = useCasesElement.offsetTop + scrollTopIndent;

        break;
      }
      case 'scroll-to-solution': {
        const solutionElement = document.getElementById('scroll-to-solution');
        scrollPosition = solutionElement.offsetTop + scrollTopIndent;

        break;
      }
      case 'scroll-to-contact-us': {
        const contactUsElement = document.getElementById('scroll-to-contact-us');
        scrollPosition = contactUsElement.offsetTop + scrollTopIndent;

        break;
      }
    }

    window.scrollBy({
      top: scrollPosition - topPosition,
      behavior: 'smooth'
    });
  }

}
