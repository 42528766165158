import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-use-cases',
  templateUrl: './use-cases.component.html',
  styleUrls: ['./use-cases.component.scss']
})
export class UseCasesComponent implements OnInit {
  data = [{
    number: '1',
    name: 'Influencer Marketing',
    text: ' Identify influencers within your audience and refine them by demographics of themselves and their audience, their interest in specific brands or topics they are talking about.'
  }, {
    number: '2',
    name: 'Fan base',
    text: 'Get the insights about your current fan base to better understand how to deal with them'
  }];

  constructor() {
  }

  ngOnInit(): void {
  }
}
